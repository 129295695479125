// define global GME variable
var GME = GME || {};

// property to access GME class definitions
GME.classes = GME.classes || {};

// property to access build in dependencies
GME.utils = GME.utils || {};

(function(){
